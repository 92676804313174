<template>
<div class="mb-9">
     <v-img v-if="localLang == 'fr'" width="100%" :src="imgHeader"></v-img>
     <v-img v-else width="100%" :src="imgHeader2"></v-img>
     <template v-if="width > 430 " >
          <v-btn v-if="localLang == 'fr'" @click="changeLang('ar')" :style="'position: absolute;  top: '+lang1.top+'px; right: '+lang1.margin+'px; border: 1px solid #2bc2ff;font-size: '+lang1.size+'px;'" :width="width<700 ? '10' : '120'" color="white" text>العربية</v-btn>
          <v-btn v-else @click="changeLang('ar')" :style="'position: absolute;  top: '+lang1.top+'px; left: '+lang1.margin+'px; border: 1px solid #2bc2ff;font-size: '+lang1.size+'px;'" :width="width<700 ? '10' : '120'" color="white" text>العربية</v-btn>
          <v-btn v-if="localLang == 'fr'" @click="changeLang('fr')" :style="'position: absolute;  top: '+lang2.top+'px; right: '+lang2.margin+'px; border: 1px solid #2bc2ff;font-size: '+lang2.size+'px;'" :width="width<700 ? '10' : '120'" color="white" text>français</v-btn>
          <v-btn v-else @click="changeLang('fr')" :style="'position: absolute;  top: '+lang2.top+'px; left: '+lang2.margin+'px; border: 1px solid #2bc2ff;font-size: '+lang2.size+'px;'" :width="width<700 ? '10' : '120'" color="white" text>français</v-btn>
     </template>
     <template v-else>
          <h5 v-if="localLang == 'fr'" @click="changeLang('ar')" :style="'position: absolute;  top:10px; right: 10px; border: 1px solid #2bc2ff;font-size:8px; color:#fff'" width="10" >العربية</h5>
          <h5 v-else @click="changeLang('ar')" :style="'position: absolute;  top: 10px; left:10px; border: 1px solid #2bc2ff;font-size: 8px; color:#fff'" width="10" >العربية</h5>
          <h5 v-if="localLang == 'fr'" @click="changeLang('fr')" :style="'position: absolute;  top: 10px; right: 40px; border: 1px solid #2bc2ff;font-size: 8px; color:#fff'" width="10" >français</h5>
          <h5 v-else @click="changeLang('fr')" :style="'position: absolute;  top:10px; left: 40px; border: 1px solid #2bc2ff;font-size: 8px; color:#fff'" width="10" >français</h5>
     </template>
     <v-img :style="
        localLang == 'fr'
          ? 'position: absolute; top: '+logo.top+'px; left: '+logo.margin+'px'
          : 'position: absolute; top: '+logo.top+'px; right: '+logo.margin+'px'
      " :height="logo.height" :width="logo.width" src="../assets/logo.png"></v-img>
     <h1 class="fullnameTxt" :style="
        localLang == 'fr'
          ? 'position: absolute;  top: '+fullname.top+'px; left:'+fullname.margin+'px; color: #fff; font-size:' + fullname.size+'px;'
          : 'position: absolute;  top:'+fullname.top+'px; right:'+fullname.margin+'px; color: #fff; font-size: ' + fullname.size+'px;'
      ">

          {{
        getInfo
          ? localLang == "fr"
            ? getInfo.fullname
            : getInfo.fullnameArab
          : ""
      }}
     </h1>
     <h1 :style="
        localLang == 'fr'
          ? ' position: absolute;  top:' +typeLabo.top+'px;  left: '+typeLabo.margin+'px; color: #fff; font-size:' +typeLabo.size+'px;'
          : 'position: absolute;  top:' +typeLabo.top+'px;  right: '+typeLabo.margin+'px; color: #fff; font-size: '+ typeLabo.size+'px;'
      ">
          {{ $t("typeLabo") }}
     </h1>
     <h1 class="enTete" :style="
        localLang == 'fr'
          ? 'position: absolute;top: ' +mes.top+'px; left: ' +mes.margin+'px;color: #fff;font-size: ' +mes.size+'px;'
          : 'position: absolute;top: ' +mes.top+'px; right: ' +mes.margin+'px;color: #fff;font-size: ' +mes.size+'px;'
      ">
          {{ getInfo ? (getInfo.type == 2 ? "Mesanalyses" : "") : "" }}
     </h1>
</div>
</template>

<script>
export default {
     data() {
          return {
               imgHeader: require('@/assets/wave.png'),
               imgHeader2: require('@/assets/wave2.png'),
               fullname: {
                    size: 70,
                    margin: 310,
                    top: 50
               },
               typeLabo: {
                    size: 30,
                    margin: 310,
                    top: 150
               },
               lang1: {
                    size: 15,
                    margin: 810,
                    top: 15
               },
               lang2: {
                    size: 15,
                    margin: 940,
                    top: 15
               },
               mes: {
                    size: 40,
                    margin: 310,
                    top: 200
               },
               logo: {
                    top: 50,
                    margin: 130,
                    height: 130,
                    width: 140,
               },
               width: "",
          }
     },
     computed: {
          getWidth() {
               return this.width
          },
          getInfo() {
               return this.$store.state.info[0];
          },
          isLoading() {
               return this.$store.state.isLoading;
          },
          localLang() {
               return this.$i18n.locale;
          },
     },
     methods: {
          changePosition(sizeName, margName, topName, sizeType, margType, topType, sizeLang1, margLang1, topLang1, sizeLang2, margLang2, topLang2, sizeMes, margMes, topMes, topLogo, margLogo, heightLogo, widthLogo) {

               this.fullname = {
                    size: sizeName,
                    margin: margName,
                    top: topName
               }
               this.typeLabo = {
                    size: sizeType,
                    margin: margType,
                    top: topType
               }
               this.lang1 = {
                    size: sizeLang1,
                    margin: margLang1,
                    top: topLang1
               }
               this.lang2 = {
                    size: sizeLang2,
                    margin: margLang2,
                    top: topLang2
               }
               this.mes = {
                    size: sizeMes,
                    margin: margMes,
                    top: topMes
               }
               this.logo = {
                    top: topLogo,
                    margin: margLogo,
                    height: heightLogo,
                    width: widthLogo,
               }

          },
          myEventHandler(e) { 
               this.width = e.currentTarget.innerWidth
               this.resizeScreen(e.currentTarget.innerWidth)
          },
          resizeScreen(size) {
               if (size <= 1440 && size > 1200) {
                    this.changePosition( /*sizeName*/ 50, /*margName*/ 300, /*topName*/ 50, /*sizeType*/ 20, /*margType*/ 300,
                         /*topType*/
                         115, /*sizeLang1*/ 15, /*margLang1*/ 610, /*topLang1*/ 15, /*sizeLang2*/ 15, /*margLang2*/ 740,
                         /*topLang2*/
                         15, /*sizeMes*/ 40, /*margMes*/ 310, /*topMes*/ 140, /*topLogo*/ 50, /*margLogo*/ 100,
                         /*heightLogo*/
                         130, /*widthLogo*/ 140)
               } else if (size <= 1200 && size > 1100) {
                    this.changePosition( /*sizeName*/ 35, /*margName*/ 270, /*topName*/ 30, /*sizeType*/ 17, /*margType*/ 270,
                         /*topType*/
                         75, /*sizeLang1*/ 10, /*margLang1*/ 90, /*topLang1*/ 15, /*sizeLang2*/ 10, /*margLang2*/ 220,
                         /*topLang2*/
                         15, /*sizeMes*/ 40, /*margMes*/ 270, /*topMes*/ 95, /*topLogo*/ 40, /*margLogo*/ 100,
                         /*heightLogo*/
                         100, /*widthLogo*/ 110)
               } else if (size <= 1100 && size > 700) {
                    this.changePosition( /*sizeName*/ 30, /*margName*/ 100, /*topName*/ 20, /*sizeType*/ 13, /*margType*/ 100,
                         /*topType*/
                         55, /*sizeLang1*/ 10, /*margLang1*/ 90, /*topLang1*/ 15, /*sizeLang2*/ 10, /*margLang2*/ 220,
                         /*topLang2*/
                         15, /*sizeMes*/ 20, /*margMes*/ 100, /*topMes*/ 70, /*topLogo*/ 20, /*margLogo*/ 10,
                         /*heightLogo*/
                         65, /*widthLogo*/ 70)
               } else if (size < 700 && size > 430) {
                    this.changePosition( /*sizeName*/ 26, /*margName*/ 70, /*topName*/ 5, /*sizeType*/ 11, /*margType*/ 70,
                         /*topType*/
                         41, /*sizeLang1*/ 8, /*margLang1*/ 5, /*topLang1*/ 15, /*sizeLang2*/ 8, /*margLang2*/ 70,
                         /*topLang2*/
                         15, /*sizeMes*/ 15, /*margMes*/ 70, /*topMes*/ 55, /*topLogo*/ 10, /*margLogo*/ 5,
                         /*heightLogo*/
                         45, /*widthLogo*/ 50)
               } else if (size <= 430) {
                    this.changePosition( /*sizeName*/ 19, /*margName*/ 70, /*topName*/ 2, /*sizeType*/ 11, /*margType*/ 70,
                         /*topType*/
                         29, /*sizeLang1*/ 8, /*margLang1*/ 5, /*topLang1*/ 15, /*sizeLang2*/ 8, /*margLang2*/ 70,
                         /*topLang2*/
                         15, /*sizeMes*/ 15, /*margMes*/ 70, /*topMes*/ 42, /*topLogo*/ 10, /*margLogo*/ 5,
                         /*heightLogo*/
                         45, /*widthLogo*/ 50)
               }
               if (size < 820) {
                    this.imgHeader = require('@/assets/wave3.png')
                    this.imgHeader2 = require('@/assets/wave3.png')
               } else {
                    this.imgHeader = require('@/assets/wave.png')
                    this.imgHeader2 = require('@/assets/wave2.png')
               }
          },
          changeLang(lang) {
               localStorage.setItem("lang", lang);
               this.$i18n.locale = lang;
               if (localStorage.getItem("lang") == "ar") {
                    this.$vuetify.rtl = true;
               } else {
                    this.$vuetify.rtl = false;
               }
               setTimeout(() => {
                    var f = document.querySelectorAll("*");

                    for (var i = 0; i < f.length; i++) {
                         if (lang == "ar") {
                              f[i].style.setProperty("font-family", "Droid", "important");
                         } else {
                              f[i].style.setProperty("font-family", "Open Sans", "important");
                         }
                    }
                    var d = document.querySelector(".enTete");
                    if (d) {
                         d.style.fontFamily = "curve";
                    }
               }, 0);
          },
     },
     mounted() {
        
        
          if (localStorage.getItem("lang") == "ar") {
               this.$i18n.locale = "ar";
               this.$vuetify.rtl = true;

          } else {
               this.$i18n.locale = "fr";
               this.$vuetify.rtl = false;
          }

     },
     destroyed() {
          window.removeEventListener("resize", this.myEventHandler);
     },
     created() {
              this.width = window.innerWidth - 15
          console.log('this.width', this.width);
this.resizeScreen(window.innerWidth)
          window.addEventListener("resize", this.myEventHandler);
          console.log(window.screen.width);
          this.$store.dispatch("getInfo");
     }
};
</script>

<style>
@font-face {
     font-family: Droid;
     src: url('../assets/font/Droid Arabic Kufi.ttf');
}

@font-face {
     font-family: Curve;
     src: url('../assets/font/learning_curve_regular_ot_ps.otf');
}

.enTete {
     font-family: Curve;
}

/* @media screen and (max-width: 1500) and (min-width: 1200)  {
  .fullnameTxt {
       font-size: 20px !important;
  }  
}*/
</style>
